export const rpaStepTypes = {
  RPA_EXECUTION: 'RPA_EXECUTION',
  RPA_FORM_EXECUTION: 'RPA_FORM_EXECUTION',
  RPA_VALIDATION: 'RPA_VALIDATION',
  RPA_VALIDATE_ELEMENT: 'RPA_VALIDATE_ELEMENT',
  RPA_VALIDATE_ELEMENT_CONTAINS: 'RPA_VALIDATE_ELEMENT_CONTAINS',
  RPA_VALIDATE_ELEMENT_EXISTS: 'RPA_VALIDATE_ELEMENT_EXISTS',
  RPA_VALIDATE_ELEMENT_DOES_NOT_EXIST: 'RPA_VALIDATE_ELEMENT_DOES_NOT_EXIST',
  RPA_VALIDATE_TEXT_EXISTS: 'RPA_VALIDATE_TEXT_EXISTS',
  RPA_VALIDATE_TEXT_DOES_NOT_EXIST: 'RPA_VALIDATE_TEXT_DOES_NOT_EXIST',
  RPA_VALIDATE_ELEMENT_CONTAINS_TEXT: 'RPA_VALIDATE_ELEMENT_CONTAINS_TEXT',
  RPA_VALIDATE_ELEMENT_DOES_NOT_CONTAIN_TEXT: 'RPA_VALIDATE_ELEMENT_DOES_NOT_CONTAIN_TEXT',
  RPA_RANDOM_VARIABLE_BY_TIME: 'RPA_RANDOM_VARIABLE_BY_TIME',
  RPA_RANDOM_VARIABLE_IN_RANGE: 'RPA_RANDOM_VARIABLE_IN_RANGE',
  RPA_EXTRACT_LAST_VALUE_BY_DELIMITER: 'RPA_EXTRACT_LAST_VALUE_BY_DELIMITER',
  RPA_EXTRACT_URL_VARIABLE: 'RPA_EXTRACT_URL_VARIABLE',
  RPA_HANDLE_ALERT: 'RPA_HANDLE_ALERT',
  RPA_SCROLL_ELEMENT_INTO_VIEW: 'RPA_SCROLL_ELEMENT_INTO_VIEW',
  RPA_OPEN_URL: 'RPA_OPEN_URL',
  RPA_PRESS_KEY: 'RPA_PRESS_KEY',
  RPA_CLICK_LINK: 'RPA_CLICK_LINK',
  RPA_CLICK_ELEMENT_BY_JS: 'RPA_CLICK_ELEMENT_BY_JS',
  RPA_SLEEP: 'RPA_SLEEP',
  RPA_TAKE_SCREENSHOT: 'RPA_TAKE_SCREENSHOT',
  RPA_INPUT_TEXT: 'RPA_INPUT_TEXT',
  RPA_SELECT_DROPDOWN: 'RPA_SELECT_DROPDOWN',
  RPA_SELECT_CHECKBOX: 'RPA_SELECT_CHECKBOX',
  RPA_CLEAR_TEXT: 'RPA_CLEAR_TEXT',
  RPA_CLICK_BUTTON: 'RPA_CLICK_BUTTON',
  RPA_CLICK_ELEMENT: 'RPA_CLICK_ELEMENT',
  RPA_DOWNLOAD_FILE: 'RPA_DOWNLOAD_FILE',
  RPA_SELECT_FRAME: 'RPA_SELECT_FRAME',
  RPA_SWITCH_WINDOW: 'RPA_SWITCH_WINDOW',
  RPA_EXPOSE_PARAMETER: 'RPA_EXPOSE_PARAMETER',
  RPA_AWAIT_EXTERNAL: 'RPA_AWAIT_EXTERNAL',
  RPA_EXTRACT_HTML_ELEMENT_TO_FILE: 'RPA_EXTRACT_HTML_ELEMENT_TO_FILE',
  RPA_UPLOAD_FILE: 'RPA_UPLOAD_FILE',
  RPA_UNSELECT_FRAME: 'RPA_UNSELECT_FRAME',
};

export const stepTypesForRender = {
  CREATE_FALLOUT_TICKET: 'CREATE_FALLOUT_TICKET',
  CREATE_SERVICE_TICKET: 'CREATE_SERVICE_TICKET',
  UPDATE_SERVICE_TICKET_STATUS: 'UPDATE_SERVICE_TICKET_STATUS',
  REST_EXECUTION: 'REST_EXECUTION',
  GET_REST_EXECUTION: 'GET_REST_EXECUTION',
  EMAIL_SENDING: 'EMAIL_SENDING',
  GIVEN: 'GIVEN',
  WHEN: 'WHEN',
  THEN: 'THEN',
  OCR_DATA_EXTRACTION: 'OCR_DATA_EXTRACTION',
  CREATE_SERVICE_TICKET_TASK: 'CREATE_SERVICE_TICKET_TASK',
  SEND_SMS: 'SEND_SMS',
  GET_CURRENT_DATE: 'GET_CURRENT_DATE',
  GET_FILE: 'GET_FILE',
  SAVE_FILE: 'SAVE_FILE',
  LOOP_START: 'LOOP_START',
  LOOP_END_EARLY: 'LOOP_END_EARLY',
  EVALUATE_EXPRESSION: 'EVALUATE_EXPRESSION',
  MAP_VALIDATION: 'MAP_VALIDATION',
  LIST_VALIDATION: 'LIST_VALIDATION',
  SET_PARAMETER_VALUE: 'SET_PARAMETER_VALUE',
  SOCR_DATA_EXTRACTION: 'SOCR_DATA_EXTRACTION',
  CREATE_EXCEL_SPREADSHEET: 'CREATE_EXCEL_SPREADSHEET',
  CREATE_EXCEL_SPREADSHEET_FROM_OBJECT: 'CREATE_EXCEL_SPREADSHEET_FROM_OBJECT',
  EXTRACT_EXCEL_DATA: 'EXTRACT_EXCEL_DATA',
  UPDATE_EXCEL_DATA: 'UPDATE_EXCEL_DATA',
  LOOKUP_EXCEL_DATA: 'LOOKUP_EXCEL_DATA',
  RETURN_FORMULA_RESULT: 'RETURN_FORMULA_RESULT',
  VALIDATE_VALUES_EXCEL_DATA: 'VALIDATE_VALUES_EXCEL_DATA',
  VALIDATE_VALUES_MATCH_EXCEL_DATA: 'VALIDATE_VALUES_MATCH_EXCEL_DATA',
  END_PROCESSING: 'END_PROCESSING',
  SIMPLYASSISTANT: 'SIMPLYASSISTANT',
  SIMPLYASSISTANT_SUMMARIZE: 'SIMPLYASSISTANT_SUMMARIZE',
  SIMPLYASSISTANT_GET_FIELDS: 'SIMPLYASSISTANT_GET_FIELDS',
  SIMPLYASSISTANT_IDENTIFY_SENTIMENT: 'SIMPLYASSISTANT_IDENTIFY_SENTIMENT',
  SIMPLYASSISTANT_IDENTIFY_INTENT: 'SIMPLYASSISTANT_IDENTIFY_INTENT',
  SIMPLYASSISTANT_TRANSLATE: 'SIMPLYASSISTANT_TRANSLATE',
  EXTRACT_DATA: 'EXTRACT_DATA',
  START_EXTRACTION: 'START_EXTRACTION',
  SAVE_RECORD: 'SAVE_RECORD',
  BATCH_PREPARATION: 'BATCH_PREPARATION',
  END_EXTRACTION: 'END_EXTRACTION',
  START_TRANSFORMATION: 'START_TRANSFORMATION',
  RECORD_TRANSFORM: 'RECORD_TRANSFORM',
  END_TRANSFORMATION: 'END_TRANSFORMATION',
  START_LOADING: 'START_LOADING',
  END_LOADING: 'END_LOADING',
  WAIT_DURATION: 'WAIT_DURATION',
  TQ_EXTRACT_TRANSFORMATION: 'TQ_EXTRACT_TRANSFORMATION',
  TQ_VALIDATION_TASK: 'TQ_VALIDATION_TASK',
  TELUS_CREATE_BILLING_ACCOUNT: 'TELUS_CREATE_BILLING_ACCOUNT',
  TELUS_CREATE_PARTY: 'TELUS_CREATE_PARTY',
  TELUS_CREATE_CUSTOMER: 'TELUS_CREATE_CUSTOMER',
  TELUS_GET_ADDRESS: 'TELUS_GET_ADDRESS',
  TRIGGER_PROCESS_DELEGATE_TASK: 'TRIGGER_PROCESS_DELEGATE_TASK',
  SSH_EXECUTION: 'SSH_EXECUTION',
  SEND_GENESYS_SMS: 'SEND_GENESYS_SMS',
  CREATE_DIARY_NOTE_SMART_DESKTOP: 'CREATE_DIARY_NOTE_SMART_DESKTOP',
  CREATE_DIARY_DT1: 'CREATE_DIARY_DT1',
  CREATE_FALLOUT_TO_DOMS: 'CREATE_FALLOUT_TO_DOMS',
  PERSIST_RETURNED_RPA: 'PERSIST_RETURNED_RPA',
  GET_ECID_BY_BAN: 'GET_ECID_BY_BAN',
  SEARCH_CUSTOMER: 'SEARCH_CUSTOMER',
  VALIDATE_CUSTOMER: 'VALIDATE_CUSTOMER',
  VALIDATE_MOBILE_NUMBER: 'VALIDATE_MOBILE_NUMBER',
  VALIDATE_IMEI: 'VALIDATE_IMEI',
  CREATE_CART: 'CREATE_CART',
  UPDATE_CART: 'UPDATE_CART',
  VALIDATE_CART: 'VALIDATE_CART',
  SUBMIT_CART: 'SUBMIT_CART',
  CHECK_ORDER: 'CHECK_ORDER',
  EXECUTE_KONG_API: 'EXECUTE_KONG_API',
  GCS_EXECUTION: 'GCS_EXECUTION',
  SFTP_EXECUTION: 'SFTP_EXECUTION',
  RPA_START: 'RPA_START',
  EXECUTE_AA_RPA: 'EXECUTE_AA_RPA',
  AWAIT_EXTERNAL_EXECUTION: 'AWAIT_EXTERNAL_EXECUTION',
  SQL_DB_EDITOR: 'SQL_DB_EDITOR',
  RETRIEVE_CALLBACK_DATA_FROM_DB: 'RETRIEVE_CALLBACK_DATA_FROM_DB',
  GENERATE_WIDGET_LINK: 'GENERATE_WIDGET_LINK',
  STORE_CALLBACK_DATA_TO_DB: 'STORE_CALLBACK_DATA_TO_DB',
  CREATE_DIARY_NOTE_CASA: 'CREATE_DIARY_NOTE_CASA',
  REPLACE_KEYS_IN_CONTENT: 'REPLACE_KEYS_IN_CONTENT',
  SEND_EMAIL_TO_AGENT: 'SEND_EMAIL_TO_AGENT',
  GET_WLN_TREATMENT_STATUS_BY_BAN: 'GET_WLN_TREATMENT_STATUS_BY_BAN',
  GENERATE_RANDOM_UUID: 'GENERATE_RANDOM_UUID',
  ORACLE_TO_POSTGRE_SQL: 'ORACLE_TO_POSTGRE_SQL',
  COLDFUSION_TO_NEXTJS: 'COLDFUSION_TO_NEXTJS',
  TICKET_RECONCILIATION_CHANGE_STATUS: 'TICKET_RECONCILIATION_CHANGE_STATUS',
  GENERATE_EXTERNAL_TASK: 'GENERATE_EXTERNAL_TASK',
  CUSTOM_GROUP_START: 'CUSTOM_GROUP_START',
  GOOGLE_DOCUMENT_AI: 'GOOGLE_DOCUMENT_AI',
  GOOGLE_FILE_DOWNLOAD: 'GOOGLE_FILE_DOWNLOAD',
  UPDATE_KNOWLEDGE_BASE: 'UPDATE_KNOWLEDGE_BASE',
  JSON_EDITOR:'JSON_EDITOR',
  SSO_EMAIL_VALIDATOR:'SSO_EMAIL_VALIDATOR',
  ...rpaStepTypes,
};

export const stepTypes = {
  START: 'START',
  END: 'END',
  TASK_SLOT: 'TASK_SLOT',
  BRANCH: 'BRANCH',
  GATEWAY: 'BRANCH_PROCESS_FLOW',
  MERGE: 'MERGE',
  LOOP_END: 'LOOP_END',
  LOOP_GROUP: 'LOOP_GROUP',
  RPA_END: 'RPA_END',
  RPA_GROUP: 'RPA_GROUP',
  CUSTOM_GROUP_END: 'CUSTOM_GROUP_END',
  CUSTOM_GROUP: 'CUSTOM_GROUP',
  ...stepTypesForRender,
};

export const BLUE_ICON_STEP_TYPES = [
  'FALLOUT_TICKET',
  'CREATE_SERVICE_TICKET',
  'GENERIC_SERVICE_TICKET',
  'LINK',
  'MAIL',
  'MESSAGE',
  'TEST_GIVEN',
  'GET_DATE_TIME',
  'EVALUATE_EXPRESSION',
  'SET_PARAMETER_VALUE',
  'CREATE_EXCEL_SPREADSHEET',
  'EXTRACT_EXCEL_DATA',
  'UPDATE_EXCEL_DATA',
  'EXTRACT_DATA',
  'START_EXTRACTION',
  'SAVE_RECORD',
  'BATCH_PREPARATION',
  'END_EXTRACTION',
  'START_TRANSFORMATION',
  'RECORD_TRANSFORM',
  'END_TRANSFORMATION',
  'START_LOADING',
  'END_LOADING',
  'TRIGGER_PROCESS_DELEGATE',
];

export const uneditableStepTypes = [
  stepTypes.START,
  stepTypes.END,
  stepTypes.TASK_SLOT,
  stepTypes.MERGE,
  stepTypes.LOOP_END,
  stepTypes.RPA_END,
];

export const stepTypeEnds = {
  [stepTypes.START]: stepTypes.END,
  [stepTypes.LOOP_START]: stepTypes.LOOP_END,
  [stepTypes.RPA_START]: stepTypes.RPA_END,
  [stepTypes.CUSTOM_GROUP_START]: stepTypes.CUSTOM_GROUP_END,
};

export const stepTypeGroups = {
  [stepTypes.LOOP_START]: stepTypes.LOOP_GROUP,
  [stepTypes.RPA_START]: stepTypes.RPA_GROUP,
  [stepTypes.CUSTOM_GROUP_START]: stepTypes.CUSTOM_GROUP,
  [stepTypes.GATEWAY]: stepTypes.MERGE,
};

export const defaultStepIds = {
  START: 'start',
  END: 'end',
};

export const DEFAULT_STEP_TYPE_ICON = 'CUSTOM';

export const promptText = {
  API_REQUEST_BODY: 'API_REQUEST_BODY',
  API_REQUEST_HEADERS: 'API_REQUEST_HEADERS',
  SERVICE_REQUEST_FIELD_MAPPING: 'SERVICE_REQUEST_FIELD_MAPPING',
  API_RESPONSE_DATA: 'API_RESPONSE_DATA',
  SPREADSHEET_EDITOR: 'SPREADSHEET_EDITOR',
  SPREADSHEET_TARGET_DATA: 'SPREADSHEET_TARGET_DATA',
  RPA_FORUM_TARGET_DATA: 'RPA_FORUM_TARGET_DATA',
  PARAM_NAME: 'PARAM_NAME',
  SELECTED_PROCESS_PARAMETERS: 'SELECTED_PROCESS_PARAMETERS',
};

export const SQUARE_STEPS_STATUS_CONFIG = {
  HAS_EXECUTION_ERROR: 'hasExecutionError',
  IS_CURRENTLY_EXECUTING: 'isCurrentlyExecuting',
  IS_CANCELLED: 'isCancelled',
};

export const STATIC_DYNAMIC_PARAM_TYPES = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC',
};
