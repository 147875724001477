import { format } from 'date-fns';
import moment from 'moment';

import routes from '../config/routes';

import { FEATURE_FLAGS } from './featureFlags';

import momentTimezone from 'moment-timezone';
import { isMobileOnly, isTablet } from 'react-device-detect';

export const DEFAULT_RETURN_VALUE = '---';
export const OBJECT_WILD_CARD_KEY = ''; // returns the whole object

export const isNullOrUndefined = (val) => val === null || val === undefined;
export const isNotNullOrUndefined = (val) => !isNullOrUndefined(val);

export const getNameInitials = (user, spaceBetween = false) => {
  if (!user?.firstName && !user?.lastName) return '';
  if (!user.lastName && user.firstName.length === 1)
    return `${user.firstName[0]}${spaceBetween ? ' ' : ''}${user.firstName[0]}`;
  if (!user.lastName) return `${user.firstName[0]}${spaceBetween ? ' ' : ''}${user.firstName[1]}`;
  return `${user.firstName[0]}${spaceBetween ? ' ' : ''}${user.lastName[0]}`;
};

export const getDayFromDateString = (str) => {
  const date = moment(str);
  return date.format('YYYY-MM-DD');
};

export const appendHashtag = (str, addSpace = true) => {
  if (addSpace) return `#${str}`;
  return `#${str}`;
};

export const accessObjectProperty = (object, key) => {
  if (key === OBJECT_WILD_CARD_KEY) return object;

  let result;
  // TODO: Should check for array (Array.isArray(object))
  if (typeof key === 'object') {
    const convertedSource = key.map((src) => src.split('.').reduce((o, i) => o[i], object));
    return convertedSource.join(' ');
  }
  try {
    result = key.split('.').reduce((o, i) => o[i], object);
  } catch (error) {}
  if (!result && result === null) return DEFAULT_RETURN_VALUE;
  return result;
};

// Note: base64ToFile is in src/utils/functions/fileImageFuncs.js
export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const formatPhoneNumber = (phoneNumberString) => {
  if (phoneNumberString?.length === 11) {
    return `(${phoneNumberString?.slice(1, 4)})-${phoneNumberString?.slice(4, 7)}-${phoneNumberString?.slice(7)}`;
  }
  if (phoneNumberString?.length === 12) {
    return `(${phoneNumberString?.slice(2, 5)})-${phoneNumberString?.slice(5, 8)}-${phoneNumberString?.slice(8)}`;
  }
  return phoneNumberString;
};

export const formatPhoneNumberCode = (phoneNumberCode) =>
  phoneNumberCode?.length === 11 ? `(${phoneNumberCode?.slice(0, 1)})` : `(${phoneNumberCode?.slice(0, 2)})`;

export const capitalizeFirstLetterOfRegion = (region) => {
  const regionStringLowerCase = region?.toLowerCase();
  return regionStringLowerCase?.slice(0, 1)?.toUpperCase() + regionStringLowerCase?.slice(1);
};

export const modifyStringENUMS = (name) => {
  if (name?.length < 1 || !name) return 'N/A';

  const replaceUnderscore = name.replaceAll('_', ' ');
  const changeToLowerCase = replaceUnderscore.toLowerCase();
  const sliceStringFromIndexOne = changeToLowerCase.slice(1);
  const finalString = replaceUnderscore[0].concat(sliceStringFromIndexOne);

  return finalString;
};

export const getByString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1');
  s = s.replace(/^\./, '');
  const a = s.split('.');

  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];

    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const convertUserTimeZoneToAbbr = (userTimezone) => momentTimezone().tz(userTimezone)?.zoneAbbr();

export const getDescriptiveDateFromDateString = (str) => {
  if (!str || str === '---') return '---';

  const date = moment(str);
  return date.format('MMMM DD, YYYY');
};

export const isTwoTimeStampsEqual = (firstTime, secondTime) => {
  if (!firstTime || !secondTime) return false;

  const firstDate = moment(firstTime).format('MMMM DD, YYYY');
  const secondDate = moment(secondTime).format('MMMM DD, YYYY');
  return firstDate === secondDate;
};

export const modifyAppliedFilterTimeStampsWithoutTime = (startTime, endTime) => {
  if (!startTime || !endTime) return '---';
  const finalString = `${getDescriptiveDateFromDateString(startTime)}   To   ${getDescriptiveDateFromDateString(endTime)}`;
  return finalString;
};

export const modifyDateTimeToDescriptive = (time) => {
  let incidentDate = '---';
  let incidentTime = '---';

  if (time && time !== '---') {
    incidentDate = new Date(time);
    incidentTime = format(incidentDate, "hh:mm aaaaa'm'").toUpperCase();
  }

  return `${getDescriptiveDateFromDateString(time)} - ${incidentTime ?? '---'}`;
};

export const sortReportingItemsByDateDesc = (reportingItems) => {
  if (Array.isArray(reportingItems)) {
    const sortedArray = [...reportingItems].sort((item1, item2) => (item1?.createdAt < item2?.createdAt ? 1 : -1));
    return sortedArray;
  }
};

// Could not ascertain which method was best to prevent the user from entering invalid characters thus both methods are available
// cleanSearchInput works but provides no user feedback (nothing happens if the user enters invalid characters)
export const firstLetterToUpperCase = (string) => {
  let modifiedString = '';
  const breakString = string?.split(',')?.map((item) => `${item.at(0).toUpperCase()}${item.slice(1, item.length)}`);

  breakString?.forEach((item) => {
    modifiedString = `${modifiedString.length > 0 ? `${modifiedString}, ${item}` : item}`;
  });

  return modifiedString ?? '---';
};

export const checkIfArrayContainsTrue = (array) => {
  if (!array || array.length < 1) return false;

  if (Array.isArray(array)) {
    return array?.includes(true || 'true');
  }
  return false;
};

export const extractStringsFromArrayOfObjects = (arrayOfObj, key) => {
  let finalString = '';
  if (arrayOfObj) {
    arrayOfObj?.forEach((item, index) => {
      if (index === 0) {
        finalString = `${item[key]}`;
        return;
      }
      finalString = `${finalString},${item[key]}`;
    });
  }

  return finalString;
};

export const getIsManagerRoutesActivated = (location) => {
  if (
    location?.pathname === routes.PROCESS_MANAGER ||
    location?.pathname === routes.TEST_MANAGER ||
    location?.pathname === routes.AGENT_MANAGER ||
    location?.pathname.includes(routes.MR_MANAGER) ||
    location?.pathname === routes.SETTINGS_ACCESS_MANAGEMENT
  ) {
    return true;
  }
};

export const calculatePercentage = (numerator, denominator) => {
  if (!denominator) return 0;

  return Math.round((numerator / denominator) * 100);
};

export const groupBy = (items, key) =>
  items?.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const checkPageFeatureFlags = (pages) => {
  const hideTopMenuItems = [];

  const grantedPages = { ...pages }?.grantedPages?.filter((page) => {
    if (page?.isHidden) {
      page?.isTopMenuItem && hideTopMenuItems.push(page?.topMenuItemId);

      return false;
    }

    return true;
  });

  const topMenuItems = { ...pages }?.topMenuItems?.filter((item) => !hideTopMenuItems.includes(item?.topMenuItemId));

  return {
    ...pages,
    grantedPages,
    topMenuItems,
  };
};

export const getMappedFeatureFlags = (flags = []) =>
  flags
    .filter((flag) => Boolean(FEATURE_FLAGS[flag]))
    .map((flag) => ({
      name: flag,
      isActive: false,
    }));

export const swapArrayElements = (arr, sourcePositionIndex, destinationPositionIndex) => {
  const sourceElement = arr[sourcePositionIndex];

  // Remove the element at source pos
  arr.splice(sourcePositionIndex, 1);

  arr.splice(destinationPositionIndex, 0, sourceElement);

  return arr;
};

// Need to find better solution
export const replaceDynamicUrl = (flag) => flag.replace('CA.svg', '{xx}.svg');

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isValidXML = (str) => {
  try {
    const dom = new DOMParser().parseFromString(str, 'text/xml');
    const errorNode = dom.querySelector('parsererror');

    return !errorNode;
  } catch (e) {
    return false;
  }
};

export const isValidHTML = (str) => {
  try {
    const dom = new DOMParser().parseFromString(str, 'text/html');
    const errorNode = dom.querySelector('parsererror');

    return !errorNode;
  } catch (e) {
    return false;
  }
};

export const isValidJavaScript = (str) => {
  try {
    // eslint-disable-next-line no-new-func
    new Function(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const base64EncodeFileName = (str) => btoa(encodeURIComponent(str));
export const base64DecodeFileName = (str) => {
  try {
    return decodeURIComponent(atob(str));
  } catch {
    return str;
  }
};

export const DEVICES = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
};

export const getDeviceType = () => {
  if (isMobileOnly) {
    return DEVICES.MOBILE;
  } else if (isTablet) {
    return DEVICES.TABLET;
  }
  return DEVICES.DESKTOP;
};
