import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  sizes: {
    headerHeight: 76,
    pageAside: 430,
  },
  boxShadows: {
    box: '1px 1px 10px 2px rgba(0, 0, 0, 0.1)',
    boxHovered: '0 0 20px 2px rgba(51, 65, 80, 0.25)',
    paramCard: '0px 0px 5px 1px rgba(0, 0, 0, 0.15)',
    table: '1px 1px 10px 2px rgba(0, 0, 0, 0.10)',
    rowHoverAction: '-17px 0px 10px 0px #F5F7F9',
    panelExpandButton: '-6px 0px 10px -2px rgba(0, 0, 0, 0.1)',
    linkedItemCard: '0px 0px 5px 0px rgba(0, 0, 0, 0.30)',
    issueIcon: '0px 0px 4px 1px rgba(0, 0, 0, 0.15)',
    checkIcon: '0px 0px 5px rgba(0, 0, 0, 0.40)',
    productCard: '0px 6px 20px 2px #00000013',
    productCheckout: '0px 4px 16px 2px rgba(0, 0, 0, 0.13)',
    productPlanToolbar: '0px 0px 0px 2px rgba(0, 0, 0, 0.10)',
    productCategory: '1px 4px 15px 0px rgba(0, 0, 0, 0.13)',
    productCategoryHover: '0px 4px 20px 2px rgba(0, 0, 0, 0.10)',
    contentLayoutScrollbar: '0px 4px 4px 0px #00000040',
    centerModalFixed: '0px 0px 10px 2px rgba(0, 0, 0, 0.15)',
    sliderThumb: '2px 2px 15px rgba(245, 123, 32, 1)',
    workflowDynamicSideModal: '1px 2px 5px 0px #00000026',
    toggleIconWrapper: '1px 0 5px rgba(0, 0, 0, 0.15)',
    workflowEditorDrawer: 'inset 0 0 5px rgba(0, 0, 0, 0.2)',
    loginCardShadow: '1px 2px 6px rgba(0, 0, 0, 0.2)',
    forgotPasswordCardShadow: '1px 2px 6px rgba(0, 0, 0, 0.2)',
    headerFooterSection: '0 0 10px 2px rgba(0, 0, 0, 0.1)',
    warningHeader: '1px 1px 10px 2px #0000001A',
  },
  borders: {
    timeline: {
      dot: {
        border: '3px solid #FFFFFF',
        outline: '3px solid #EAE1E1',
      },
      content: '6px solid #808080',
    },
    table: {
      default: '1px solid #CFD3DA',
      separator: '2px solid #2D3A47',
      hover: '2px solid #2D9CDB',
      cell: {
        hover: {
          // use :before pseudo element for cell
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          boxShadow: '0px 0px 0px 2px #4299FF inset',
        },
      },
    },
    chip: { default: '1px solid #DFDFDF' },
  },
  filters: {
    dropShadows: {
      fab: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      whiteBgTooltip: 'drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.15))',
    },
  },
  transitions: { default: '250ms ease-in-out' },
  colors: {
    primary: '#2d3a47',
    secondary: '#f57b20',
    secondaryHover: '#ff5e00',
    secondaryBg: '#FEEFE4',
    tertiary: '#FEF2E9',
    tertiaryHover: '#FFCEAB',
    background: '#FBFBFB',
    black: '#000000',
    white: '#ffffff',
    transparentWhite: '#FFFFFF00',
    danger: '#ff5252',
    success: '#4BB543',
    statusResolved: '#5f9936',
    statusResolvedBackground: '#ebf2e6',
    statusResolvedFadedChart: '#BCDFA4',
    statusAssigned: '#e7bb09',
    statusAssignedBackground: '#fcf7e0',
    statusAssignedFadedChart: '#F1DE90',
    statusOverdue: '#e03b24',
    statusOverdueBackground: '#fbe7e4',
    statusOverdueFadedChart: '#EC9286',
    statusUnassigned: '#3865a3',
    statusUnassignedBackground: '#e6ecf4',
    charcoal: '#2D3A47',
    lighterColor: '#FEF2E9',
    information: '#808080',
    linkColor: '#2075f5',
    inputBorder: '#C4C4C4',
    lightGray: '#909090',
    lightGray2: '#edeff5',
    lightGray3: '#f8f9fa',
    validationError: '#E03B24',
    dividerColor: '#D8D8D8',
    dividerDarkColor: '#8A96AC80',
    mediumDividerColor: '#2d3a4750',
    buttonSpecial: '#2075f5',
    lightBlue: '#209CF5',
    symphonaDarkBlue: '#11273F',
    symphonaBlue: '#0185FF',
    chatBotSilverFooter: '#F3F3F3',
    accordionHover: '#dadfe8',
    accordionBg: '#F8F9FA',
    accordionBgHover: '#EAEDF1',
    passwordStrengthUndefined: '#E5E5E5',
    optional: '#909090',
    borderNoError: '#c4c4c4',
    disabledBtnBg: '#D4D4D4',
    disabledBtnText: '#8A8A8A',
    statusDeactivatedBg: '#eee',
    phonePlusOneBg: '#ececec',
    passwordIconHover: '#969CA3',
    secondaryBtnHoverBg: '#DD6E1B',
    dropdownOptionBgHover: '#DEEAFF',
    textColorOptionTwo: '#7C8C9B',
    bgColorOptionTwo: '#F2F4F6',
    tableNoCellValue: '#B0B4BA',
    tableEditableCellBg: '#EAEDF1',
    tableEditableCellFocusBorder: '#4299FF',
    chipBg: '#F1F1F1',
    hoverPopoverItem: '#F0F0F0',
    timelineConnector: '#EAE1E1',
    scrollThumbBgAlt: '#F2F2F2',
    altoGray: '#DFDFDF',
    ghostGray: '#CACCCE50',
    ironGray: '#e4e6e7', // ghostGray with opacity 0.5
    timberwolfGray: '#D9D9D9',
    galleryGray: '#EDEDED',
    cardGridItemBg: '#F5F6F8',
    cardGridItemBgHover: '#E4E8EC',
    cardGridItemBorder: '#DFE4ED',
    shortcutItemHoverBg: '#F2F4F7',
    locationBg: '#F4F5F6',
    iconBgOrange: '#FFECDF',
    iconBgBlue: '#DFEEFF',
    iconBgHover: '#B6D7FF',
    iconBgGreen: '#E0FFDF',
    iconColorBlue: '#4299FF',
    iconColorOrange: '#F57B20',
    violetBg: '#EFDFF4',
    dustyGray: '#AAA2A2',
    paleAsh: '#F8F8F8',
    darkGray: '#939393',
    peach: '#FFEEE1',
    mistyRose: '#FFBE8E',
    grayBg: '#f6f6f6',
    darkSky: '#4586DF',
    rajah: '#FAAF77',
    portage: '#79A7E5',
    athensGray: '#EAECF1',
    peachPuff: '#ffd7bb',
    tooltipSecondary: '#D2D2D2',
    tableScrollBg: '#F4F4F4',
    tableScrollThumb: '#C7CDD3',
    tableRowHover: '#F5F7F9',
    platinum: '#DDDDDD',
    antiFlashWhite: '#F6F7F9',
    iron: '#D3D5E2',
    mercury: '#E9E9E9',
    regentGray: '#8A96AC',
    lightGrayBlue: '#F0F5FB',
    lightGrayBlue2: '#F4F7FB',
    mainGrayBlue: '#0090CE',
    symphonaLighBlue: '#D7ECFF',
    palePeach: '#FEEBDE',
    sonicSilver: '#767676',
    paleGrayBlue: '#EEF1F4',
    frenchGray: '#CAD0DD',
    lightPink: '#FFE2CC',
    slateGray: '#9BA1A7',
    lightPinkRed: '#FEF1E9',
    water: '#EEF3F9',
    geyser: '#D7DDE8',
    lightGrey: '#D3D3D3',
    vikingBlue: '#65B6DC',
    darkHotPink: '#C6005F',
    oceanBlue: '#C1D3E9',
    grassGreen: '#28A826',
    disabledLinkColor: '#EFF1F3',
    blueLink: '#2075F5',
    lightLinkColor: '#E0F0FF',
    greyIconBg: '#E5E8EE',
    darkOrange: '#FFDFC8',
    darkOrangeShadeSecond: '#DF6C16',
    graySilver: '#DCE1E8',
    lightBlack: '#1D1D1D',
    spaceGrayLight: '#444',
    spaceGray: '#393939',
    spaceGrayDark: '#222',
    darkBlackModalBg: '#000000B2',
    skyBlue: '#4198FF',
    grayishBlue: '#DDE1EC',
    stormySilver: '#757E86',
    publicFormThemeLightBg: '#414141',
    publicFormThemeLightOutline: '#C2CDD9',
    publicFormThemeDarkHover: '#4141419c',
    dragAndDropGreyBorder: '#2D3A47A6',
    dragAndDropGreyBg: '#F8F9FC',
    darkOrangeOnHover: '#E06C15',
    defaultScrollBar: '#00000070',
    lavender: '#F1E8FF',
    lavenderHover: '#E2D0FF',
    mustardBrown: '#AB7C04',
    tealishBlue: '#E7F0FF',
    cyanBlue: '#D3E5FF',
    linkWater: '#DDE5ED',
    stormyGrey: '#F2F4F8',
    salmonPink: '#FEE6E6',
    alertRed: '#D1191A',
    canceledGrey: '#DDE5ED',
    darkGrayHoverFilterIcon: '#D3D9E1',
    gray2: '#e6e6e6',
    gunPowder: '#3f3d56',
    starYellow: '#F6C500',
    archiveGray: '#ADBED0',
    reddishOrange: '#FEC295',
    raven: '#757E87',
    lawnGreen: '#47A100',
    kellyGreen: '#55C000',
    lavenderIndigo: '#8651E6',
    azul: '#2068F5',
    stepLightRedBg: '#FFE8E8',
    mirage: '#0C142B',
    lightCyanBlue: '#B5C1CC',
    lightBlueShade: '#DBDFE8',
    mischkaShade: '#CDD4DD',
    silverSandGray: '#C1C1C1',
    peachOrange: '#FFF2EA',
    darkOrange2: '#FF7D1C',
    shinyOrange: '#FFA149',
    cantaloupe: '#FFA827',
    blackEel: '#444444',
    darkerGray: '#393939',
    oil: '#222222',
    skyLightBlue: '#EBEDF4',
    jetStreamGray: '#C5CBD6',
    purple: '#7B20F5',
    coolGray: '#949CA5',
    loblolly: '#C2C8CE',
    aliceBlue: '#ECF5FF',
    royalBlue: '#305BF5',
    royalBlueHover: '#CCE3FF',
    silver: '#c6c6c6',
    darkVerticalBorder: '#BDBDBD',
    lightRedError: '#fcf4f4',
    blueBayoux: '#51667B',
    defaultCustomSelectText: '#333333',
    blueMintCream: '#F4FBFF',
    ultraMarineBlue: '#305CFA',
    butterflyBlue: '#21A9F6',
    empireYellow: '#F6CF00',
    grapefruit: '#D23722',
    lilyWhite: '#E7F6FF',
    mediumGray: '#686868',
    metallicSilver: '#BEC4CA',
  },
  statusColors: {
    yellow: {
      color: '#e7bb09',
      bg: '#fcf7e0',
    },
    blue: {
      color: '#3865a3',
      bg: '#e6ecf4',
    },
    green: {
      color: '#5F9936',
      bg: '#EBF2E6',
    },
    red: {
      color: '#e03b24',
      bg: '#fbe7e4',
    },
    grey: {
      color: '#2d3a47',
      bg: '#eaeaea',
    },
    violet: {
      color: '#8936A6',
      bg: '#EFDFF4',
    },
    blueberry: {
      color: '#4C36A6',
      bg: '#DBD5F5',
    },
    marinerBlue: {
      color: '#2070CE',
      bg: '#CDDEF3',
    },
    easternBlue: {
      color: '#1D9AAC',
      bg: '#D0ECF0',
    },
    greenOnion: {
      color: '#54A229',
      bg: '#D3F0D0',
    },
    savingsGreen: {
      color: '#38780A',
      bg: '#E7F4DE',
    },
    venetianRed: {
      color: '#CF1010',
      bg: '#FFE6E6',
    },
    darkGrassGreen: {
      color: '#38780A',
      bg: '#E7F4DE',
    },
    burntYellow: {
      color: '#D7AC00',
      bg: '#FFF6CC',
    },
    linkWater: {
      color: '#2D3A47',
      bg: '#DDE5ED',
    },
    limedSpruce: {
      color: '#384452',
      bg: '#DDE5ED',
    },
    mutedBlue: {
      color: '#3865A3',
      bg: '#E3EFFF',
    },
  },
  companyColors: {
    telus: {
      color: '#3F7E00',
      topGradient: { start: '#7ACA00', end: '#E3FFB9' },
      bottomGradient: { start: '#48296D', end: '#E7D3FF' },
    },
    symphona: {
      color: '#0185ff',
      topGradient: { start: '#5cb3ff', end: '#319eff' },
      bottomGradient: { start: '#71ddff', end: '#8fe4ff' },
    },
    govtOfAlberta: {
      color: '#5D9DC6',
      topGradient: { start: '#5D9DC6', end: '#BDE5FF' },
      bottomGradient: { start: '#FFF', end: '#8FD3FF' },
    },
  },
  iconColors: {
    orange: {
      color: '#F06F0E',
      bg: '#FFE7D6',
    },
    green: {
      color: '#5A9200',
      bg: '#DCF6B2',
    },
    lightGreen: {
      color: '#209A3B',
      bg: '#EAFFEF',
    },
    lightOrange: {
      color: '#F57B20',
      bg: '#FFF8F3',
    },
    lightPurple: {
      color: '#7E20F5',
      bg: '#F6EEFF',
    },
    lightYellow: {
      color: '#AB7C04',
      bg: '#FFFAE4',
    },
    lightRed: {
      color: '#D63841',
      bg: '#FFEEEF',
    },
    lightMagenta: {
      color: '#FF00B8',
      bg: '#FFF7FD',
    },
    teal: {
      color: '#209A3B',
      bg: '#C5F5D0',
    },
    cyan: {
      color: '#00A299',
      bg: '#CBF5F3',
    },
    bluishCyan: {
      color: '#0090CE',
      bg: '#CFF1FF',
    },
    blue: {
      color: '#4946DB',
      bg: '#D0DDFF',
    },
    purple: {
      color: '#7F47E5',
      bg: '#E9DDFF',
    },
    purpleMagenta: {
      color: '#B018BD',
      bg: '#FCD9FF',
    },
    magenta: {
      color: '#E53388',
      bg: '#FFD0E6',
    },
    red: {
      color: '#D63841',
      bg: '#FFDADC',
    },
    gray: {
      color: '#696969',
      bg: '#E1E1E1',
    },
    charcoal: {
      color: '#2D3A47',
      bg: '#D0DAE3',
    },
    lightGray: {
      color: '#474747',
      bg: '#F6F6F6',
    },
    lightBlue: {
      color: '#1882FF',
      bg: '#F2F8FF',
    },
  },
  executionStatusesColors: {
    green: '#58B218',
    yellow: '#FFED9D',
    red: '#EB3F27',
    blue: '#D3E4FC',
    charcoal: '#2D3A47',
  },
});
