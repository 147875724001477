export const ERROR_TITLE = 'Login Failed';

export const TELUS_ENV_ACTIVATE_KEY = 'true';

export const ERROR_EXCEPTION_MAP = {
  BadCredentialsException: {
    title: ERROR_TITLE,
    message: 'Invalid username or password',
  },
  DisabledException: {
    title: ERROR_TITLE,
    message: "Your organization's account has been disabled. Contact support for more information.",
  },
  LockedException: {
    title: ERROR_TITLE,
    message: 'Your user account has been locked. Contact your admin for support.',
  },
  InternalAuthenticationServiceException: {
    title: ERROR_TITLE,
    message: "Your organization's account has been disabled. Contact support for more information.",
  },
};
