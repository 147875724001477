import React, { Suspense, useEffect, useRef, useState } from 'react';
import { FlagsProvider } from 'react-feature-flags';
import { hotjar } from 'react-hotjar';
import { matchPath, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useSetRecoilState } from 'recoil';
import AppLayout from './Components/AppLayout/AppLayout';
import { TELUS_ENV_ACTIVATE_KEY } from './Components/Auth/constants/login';
import { useGetTemplateById } from './Components/Auth/hooks/useGetTemplateById';
import useUtilizeTemplate from './Components/Auth/hooks/useUtilizeTemplate';
import ProtectedRoutes from './Components/ProtectedRoute/ProtectedRoutes';
import CenterModalFixed from './Components/shared/REDISIGNED/modals/CenterModalFixed/CenterModalFixed';
import Spinner from './Components/shared/Spinner/Spinner';
import { NotFound } from './Components/TechPages/NotFound';
import ScbDFC from './Components/TelusWebForm/ScbDFC';
import ScbMobility from './Components/TelusWebForm/ScbMobility';
import Unauthorized from './Components/Unauthorized/Unauthorized';
import ViewTemplateModal from './Components/ViewTemplateModal/ViewTemplateModal';
import { getTemplateEditorRoute } from './Components/WorkflowEditor/utils/helperFunctions';
import { getRoutesWithExactMatch, getTopItemsRedirectModels, mapPermissionsWithPages } from './config/appRoutes';
import hotjarConfig from './config/hotjarConfig';
import { validateReadPermissionBasedOnRole } from './config/roles';
import routes from './config/routes';
import { ConversationProvider } from './contexts/ConversationContext';
import DataProvider from './contexts/DataProvider';
import { NotificationProvider } from './contexts/NotificationContext';
import { SideDrawerProvider } from './contexts/SideDrawerContext';
import { useUser } from './contexts/UserContext';
import { useGetIssuesCategoriesConfig } from './hooks/issue/useCategoriesConfig';
import useDeviceCheck from './hooks/useDeviceCheck';
import { useLocalStorage } from './hooks/useLocalStorage';
import { getGlobalPermissionsFeatures, getPages } from './Services/axios/permissions';
import { getIsAccountLocked } from './Services/axios/userAxios';
import { getWorkflowProcessTypes } from './Services/axios/workflowEditor';
import { featureFlags, globalState, issuesCategories, organizationProcessTypes, templateState } from './store';
import { checkPageFeatureFlags, getIsManagerRoutesActivated, getMappedFeatureFlags } from './utils/helperFunctions';

const ForgotPassword = React.lazy(() => import('./Components/Auth/ForgotPassword/ForgotPassword'));
const CreateNewPassword = React.lazy(() => import('./Components/Auth/ForgotPassword/CreateNewPassword'));
const Auth = React.lazy(() => import('./Components/Auth/Auth'));
const CreateNewAccount = React.lazy(() => import('./Components/Auth/CreateNewAccount/CreateNewAccount'));

export const DynamicRoutes = () => {
  useDeviceCheck();

  const isTelusEnvActivated = import.meta.env.VITE_IS_TELUS_ENV_ACTIVATED === TELUS_ENV_ACTIVATE_KEY;

  const scrollToTopRef = useRef();
  const { user, loadingUser } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useRecoilState(globalState);
  const [flags, setFlags] = useRecoilState(featureFlags);
  const setProcessTypes = useSetRecoilState(organizationProcessTypes);
  const setIssuesCategories = useSetRecoilState(issuesCategories);
  const [lockedUserAccountReason, setLockedAccountReason] = useState();
  const { data: categoriesConfig, isLoading: isIssueCategoriesLoading } = useGetIssuesCategoriesConfig({
    enabled: !!user && !user.isTemp,
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const [isViewTemplateModalOpen, setIsViewTemplateModalOpen] = useState(false);
  const [appliedTemplateState, setAppliedTemplateState] = useRecoilState(templateState);
  const { template, templateId, isTemplateLoading } = useGetTemplateById();
  const [localStorageTemplateId, setLocalStorageTemplateId] = useLocalStorage('templateId', null);
  const { utilizeTemplate, isTemplateUtilizing } = useUtilizeTemplate({
    onSuccess: (data) => {
      setAppliedTemplateState((prev) => ({ ...prev, templateEditorId: data }));

      if (localStorageTemplateId) {
        const route = getTemplateEditorRoute(data, template?.templateType);
        navigate(route);
      } else {
        setLocalStorageTemplateId(templateId);
        setIsViewTemplateModalOpen(true);
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data || 'Something went wrong');
    },
  });

  useEffect(() => {
    if (!!template && templateId && !isTemplateLoading && user) {
      if (appliedTemplateState.templateId !== templateId) {
        setAppliedTemplateState((prev) => ({ ...prev, templateId, isIntroBubbleSeen: false }));
        utilizeTemplate({ templateId, orgId: user.organization.id });
      }
    }
  }, [template, templateId, isTemplateLoading, user]);

  useEffect(() => {
    let script;

    if (!isTelusEnvActivated) {
      script = document.createElement('script');
      script.src = import.meta.env.VITE_CHARGE_BEE_URL;
      script.async = true;

      script.onload = () => {
        console.log('Chargebee script loaded successfully');

        try {
          if (window.Chargebee) {
            window.Chargebee?.init({
              site: import.meta.env.VITE_CHARGE_BEE_SITE,
              publishableKey: import.meta.env.VITE_CHARGE_BEE_PUBLISH_API_KEY, // TODO: later this should be accessed from GCP secret manager or any other secret resource
            });
            console.log('Chargebee initialized successfully');
          } else {
            console.error('Chargebee object not found');
          }
        } catch (error) {
          console.error('Error initializing Chargebee:', error);
        }
      };

      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [isTelusEnvActivated]);

  useEffect(() => {
    if (user) {
      const recentlyViewedPages = JSON.parse(localStorage.getItem(`recentlyViewedPages_${user.id}`)) || [];

      setState((prevState) => ({
        ...prevState,
        recentlyViewedPages,
      }));

      setIsInitialized(true);
    }
  }, [user]);

  useEffect(() => {
    if (isInitialized) {
      const recentlyViewedPagesFromStorage = JSON.parse(localStorage.getItem(`recentlyViewedPages_${user.id}`)) || [];
      const viewedPages =
        recentlyViewedPagesFromStorage?.length >= 10
          ? [location.pathname, ...recentlyViewedPagesFromStorage.slice(0, 9)]
          : [location.pathname, ...recentlyViewedPagesFromStorage];

      const recentlyViewedPages = [...new Set(viewedPages)];

      setState({
        ...state,
        recentlyViewedPages,
        currentPageId: state?.pages?.grantedPages?.find((page) => {
          // getRoutesWithExactMatch is required to handle falsePositive cases such as -
          // matchPath returns true during the comparison of following -
          // 1 - "/Sell/OrderManager/ProductOfferings"
          // 2- "/Sell/OrderManager/123234343"
          // 2nd route is for individual Id but matchPath thinks "ProductOfferings" of point 1 as Id and returns true
          // which should not happen as "/dashboard/Sell/OrderManager/ProductOfferings" is not the ID route

          if (getRoutesWithExactMatch()?.includes(location.pathname) && page.pageUrlPath !== location.pathname) {
            return;
          }

          return matchPath(page.pageUrlPath, location.pathname);
        })?.pageId,
      });

      localStorage.setItem(`recentlyViewedPages_${user.id}`, JSON.stringify(recentlyViewedPages));
    }
  }, [location.pathname, state.currentPageId, isInitialized]);

  useEffect(() => {
    if (user && !user.isTemp) {
      getPages().then((result) => {
        setFlags({
          ...flags,
          featureFlags: getMappedFeatureFlags(result?.data?.featureFlags),
        });

        // Temp removal of temp remove :)
        // Temp remove Process Orchestrator as per SC-4171
        // const filterGrantedPages = result?.data?.grantedPages?.filter(
        //   (page) => page.pageUrlPath !== routes.PROCESS_ORCHESTRATION
        // );

        setState({
          ...state,
          // pages: checkPageFeatureFlags({ ...result?.data, grantedPages: filterGrantedPages }),
          pages: checkPageFeatureFlags(result?.data),
        });
      });

      getIsAccountLocked()
        .then((result) => {
          if (result?.data) {
            setLockedAccountReason(result.data);
          }
        })
        .catch(console.log);

      getWorkflowProcessTypes()
        .then((result) => {
          setProcessTypes(result);
        })
        .catch(console.log);
    } else {
      getGlobalPermissionsFeatures().then((data) => {
        setFlags({
          ...flags,
          featureFlags: getMappedFeatureFlags(data),
        });
      });
    }
  }, [user]);

  useEffect(() => {
    if (categoriesConfig) {
      setIssuesCategories(categoriesConfig);
    }
  }, [categoriesConfig]);

  useEffect(() => {
    // Initialize after all async calls are done -> User must be logged in and not isTemp;
    if (hotjarConfig.enabled && state.pages && !hotjar.initialized()) {
      hotjar.initialize(hotjarConfig.hjid, hotjarConfig.hjsv);
    }
  }, [state.pages]);

  if (loadingUser || isIssueCategoriesLoading || isTemplateUtilizing) {
    // TODO: remove later (when /category api response is faster)
    return <Spinner global />;
  }

  return (
    <FlagsProvider value={flags.featureFlags}>
      {user && !user.isTemp ? (
        <DataProvider user={user}>
          <NotificationProvider>
            <ConversationProvider>
              <SideDrawerProvider>
                <AppLayout pages={state.pages}>
                  <Suspense fallback={<Spinner global />}>
                    <Routes>
                      {mapPermissionsWithPages(state.pages?.grantedPages).map(
                        ({ pageId, pathName, component, permissionStatus, ...rest }) => {
                          const isManagerRouteActivated = getIsManagerRoutesActivated(location);
                          return (
                            component &&
                            pathName && (
                              <Route
                                key={pageId}
                                {...rest}
                                path={`${pathName}`}
                                scrollToTopRef={scrollToTopRef}
                                element={
                                  <ProtectedRoutes
                                    component={component}
                                    alternativeComponent={Unauthorized}
                                    visible
                                    readOnly={validateReadPermissionBasedOnRole(permissionStatus)}
                                    isAccountDisabled={!!lockedUserAccountReason}
                                    isAccountDisabledText={lockedUserAccountReason}
                                    isManagerRouteActivated={isManagerRouteActivated}
                                  />
                                }
                              />
                            )
                          );
                        }
                      )}
                      {getTopItemsRedirectModels(state.pages?.grantedPages).map(({ route, redirect }, index) => (
                        <Route key={index} path={route} exact element={<Navigate to={redirect} />} replace />
                      ))}
                      ;
                      <Route
                        path="/demo-page"
                        element={<Navigate to={routes.AGENT_TESTER + location.search} replace />}
                      />
                      <Route path={routes.FORGOT_PASSWORD} exact element={<Navigate to={routes.DEFAULT} replace />} />
                      <Route
                        path={routes.CREATE_NEW_PASSWORD}
                        exact
                        element={<Navigate to={routes.DEFAULT} replace />}
                      />
                      <Route exact path={routes.REGISTER} element={<Navigate to={routes.DEFAULT} replace />} />
                      <Route path="/" element={<Navigate to={routes.DEFAULT} replace />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Suspense>

                  <CenterModalFixed
                    open={isViewTemplateModalOpen}
                    onClose={() => setIsViewTemplateModalOpen(false)}
                    enableScrollbar={false}
                    maxWidth="583"
                  >
                    <ViewTemplateModal
                      onClose={() => setIsViewTemplateModalOpen(false)}
                      template={template}
                      templateEditorId={appliedTemplateState.templateEditorId}
                    />
                  </CenterModalFixed>
                </AppLayout>
              </SideDrawerProvider>
            </ConversationProvider>
          </NotificationProvider>
        </DataProvider>
      ) : (
        <Suspense fallback={<Spinner global />}>
          <Routes>
            {/* just to support old links */}
            <Route path="/dashboard/dfc-scb-requester" replace element={<Navigate to="/scb/dfc" />} />
            <Route path="/dashboard/scb/mobility" element={<Navigate to="/scb/mobility" />} />
            <Route path="/dashboard/scheduledCallbackRequester" element={<Navigate to="/scb/mobility" />} />
            <Route path="/dashboard/scb/dfc" element={<Navigate to="/scb/dfc" />} />

            <Route path="/scb/mobility" element={<ScbMobility />} />
            <Route path="/scb/dfc" element={<ScbDFC />} />
            <Route path={routes.DEFAULT} exact element={<Auth />} />
            <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={routes.CREATE_NEW_PASSWORD} element={<CreateNewPassword />} />
            <Route exact path={routes.REGISTER} element={<CreateNewAccount />} />
            <Route path="/" element={<Navigate to={routes.DEFAULT} replace />} />
            <Route path="*" element={<Auth />} />
          </Routes>
        </Suspense>
      )}
    </FlagsProvider>
  );
};
