export default Object.freeze({
  // common routes
  DEFAULT: '/',
  REGISTER: '/register',
  WARNING: '/warning',
  SUPPORT: '/support',
  PROFILE: '/profile',
  PROFILE_CHANGE_PASSWORD: '/profile/change-password',
  PROFILE_EDIT: '/profile/edit',
  UNAUTHORIZED: '/unauthorized',
  FORGOT_PASSWORD: '/forgot-password',
  CREATE_NEW_PASSWORD: '/create-new-password',
  FILES: '/file-manager',

  // serve
  TICKETS: '/serve/tickets',
  TICKETS_FULLVIEW: '/serve/tickets/:ticketId',

  // resolve
  FALLOUT_TICKETS: '/resolve/fallout-tickets',
  FALLOUT_TICKETS_FULL_VIEW: '/resolve/fallout-tickets/:falloutTicketId',

  // flow
  FLOW: '/flow',
  PROCESS_MANAGER: '/flow/manager',
  PROCESS_MANAGER_INFO: '/flow/manager/editor/:processId',
  PROCESS_ORCHESTRATION: '/flow/orchestrator',
  PROCESS_ORCHESTRATION_DETAILS: '/flow/orchestrator/:processId',
  PROCESS_ORCHESTRATION_EDIT: '/flow/orchestrator/:processId/edit',
  PROCESS_ORCHESTRATION_HISTORY: '/flow/orchestrator/:processId/history/:executingId',
  PROCESS_TRIGGER: '/flow/trigger',
  EVENT_TRIGGER_DETAILS: '/flow/trigger/event-trigger',
  EVENT_TRIGGER_DETAILS_EDIT: '/flow/trigger/event-trigger/:id',
  PROCESS_DATA_VISUALIZER: '/flow/process-data-visualizer',
  BOA_DASHBOARD: '/flow/dashboard',
  BOA_DASHBOARD_PROCESS_VIEW: '/flow/dashboard/:workflowName',
  PROCESS_HISTORY: '/flow/history',
  PROCESS_HISTORY_BULK_PREVIEW: '/flow/history/bulk-preview/:ticketId',

  // converse
  CONVERSE: '/converse',
  CHAT: '/converse/live-chat',
  AGENT_MANAGER: '/converse/manager',
  AGENT_MANAGER_DIAGRAM: '/converse/manager/editor/:serviceTypeId',
  AGENT_TESTER: '/converse/agent-tester',
  CONVERSATION_HISTORY: '/converse/conversation-history',
  CONVERSE_DASHBOARD: '/converse/dashboard',

  // test
  TEST: '/test',
  TEST_MANAGER: '/test/manager',
  TEST_SUITE_DETAILS: '/test/manager/suite/:suiteId',
  TEST_CASE_DETAILS: '/test/manager/case/:caseId',
  TEST_GROUP_DETAILS: '/test/manager/group/:groupId',
  TEST_EDITOR: '/test/manager/editor',
  TEST_EDITOR_INFO: '/test/manager/editor/:testCaseId',
  TEST_DASHBOARD: '/test/dashboard',
  TEST_HISTORY: '/test/history',

  // migrate
  MIGRATE: '/migrate',
  MR_MANAGER: '/migrate/manager',
  MR_HISTORY: '/migrate/history',
  MR_MANAGER_MAPPING_EDITOR: '/migrate/manager/:MappingEditorId',

  // sell
  SELL: '/sell',
  CUSTOMER_MANAGER: '/sell/customer-manager',
  CUSTOMER_PROFILE: '/sell/customer-manager/:CustomerId',
  ORDER_MANAGER: '/sell/order-manager',
  PRODUCT_ORDER_HISTORY_DETAILS: '/sell/order-manager/:orderId',
  PRODUCT_OFFERINGS: '/sell/order-manager/product-offerings',
  PRODUCT_OFFERINGS_CHECKOUT: '/sell/order-manager/product-offerings/checkout',
  PRODUCT_OFFERINGS_CONFIRMATION: '/sell/order-manager/product-offerings/confirmation',

  // settings
  SETTINGS: '/settings',
  SETTINGS_ACCESS_MANAGER: '/settings/access-management',
  SETTINGS_ACCESS_MANAGER_USER_DETAILS: '/settings/access-management/users/:id',
  SETTINGS_ACCESS_MANAGER_USER_CHANGE_PASSWORD: '/settings/access-management/users/:id/change-password',
  SETTINGS_ACCESS_MANAGER_EDIT_USER: '/settings/access-management/users/:id/edit',
  SETTINGS_ACCESS_MANAGER_USERS: '/settings/access-management/users',
  SETTINGS_ACCESS_MANAGER_PERMISSION_GROUPS: '/settings/access-management/permission-groups',
  SETTINGS_ACCESS_MANAGER_PERMISSION_GROUP_DETAILS: '/settings/access-management/permission-groups/:id',
  SETTINGS_USER_GROUPS: '/settings/access-management/user-groups',
  SETTINGS_USER_GROUPS_SINGLE_ITEM: '/settings/access-management/user-groups/:id',
  SETTINGS_GENERAL_TAB: '/settings/general',
  SETTINGS_GENERAL_TAB_CREATE_KNOWLEDGE_BASE: '/settings/general/create-knowledge-base',
  SETTINGS_GENERAL_TAB_EDIT_KNOWLEDGE_BASE: '/settings/general/edit-knowledge-base/:knowledgeBaseId',

  SETTINGS_FRONT_OFFICE_TAB: '/settings/front-office',
  SETTINGS_BACK_OFFICE_TAB: '/settings/back-office',
  SETTINGS_USAGE_TAB: '/settings/usage',
  SETTINGS_BILLING_TAB: '/settings/billing',

  SETTINGS_CREATE_SERIVCE_TICKET_TYPE: '/settings/front-office/create-service-ticket-type',
  SETTINGS_EDIT_SERIVCE_TICKET_TYPE: '/settings/front-office/edit-service-ticket-type/:id',
  SETTINGS_CREATE_CHAT_WIDGET: '/settings/front-office/create-chat-widget',
  SETTINGS_EDIT_CHAT_WIDGET: '/settings/front-office/edit-chat-widget/:id',
  SETTINGS_EDIT_CHAT_WIDGET_WITHOUT_ID: '/settings/front-office/edit-chat-widget',

  SETTINGS_CREATE_PARAMETER_SET: '/settings/back-office/create-parameter-set',
  SETTINGS_EDIT_PARAMETER_SET: '/settings/back-office/edit-parameter-set/:id',
});

export const navLinkTitles = {
  PROJECTS: 'Projects',
  DASHBOARD: 'Dashboard',
  BOA_DASHBOARD: 'BOA Dashboard',
  BOA_DASHBOARD_LOWER_CASE: '/flow/dashboard/',
  BOA_DASHBOARD_LOWER_CASE_TITLE: '/flow/dashboard',
  WORKFLOW_NAME: '/:workflowName',
};

export const modifyTitlesKeysENUM = { UNDERSCORE: '_' };

export const accountDisabledKeys = {
  USER_DISABLED: 'to your user account being cancelled by the Symphona team',
  USER_DELETED: 'to your user account being deleted by the Symphona team',
  SUBSCRIPTION_AUTO_CANCELLED: 'to your user account being cancelled by the Symphona team',
};
